import React, { useEffect } from 'react';
import styled from 'styled-components';

const Styled404 = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Page404 = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location = '../';
    }, 4000);
  });
  return (
    <Styled404>
      <div>
        <h1>404: Page not found</h1>
        <span>Redirecting you shortly.</span>
      </div>
    </Styled404>
  );
};

export default Page404;
